<template>
  <base-layout>
    <div class="full-mode">
      <div class="flex bg-white ">
        <div class="px-4 pt-10 bg-white min-w-max w-1/5">
          <content-section class="border border-gray-400 override">
            <oto-page-title
              :title="$t('components.settingsManagement.navigation.title')"
              class="inline-block mb-6"
            />
            <div class="-mb-3">
              <!-- <anchor-tabs :tabs="tabs" /> -->
              <settings-nav />
            </div>
          </content-section>
        </div>
        <div
          class="w-4/5 pr-16 bg-white pl-main override-2  overflow-y-auto"
          style="height: calc(100vh - 20px )"
        >
          <!-- <content-section class="override-2">
          <router-view />
        </content-section> -->
          <div class="pt-8 pb-8 ">
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <div class="responsive-mode">
      <div class="w-full bg-white  overflow-y-auto" style="height: 100vh">
        <div class="sticky top-0 bg-white py-3  z-70 px-2">
          <oto-page-title title="Settings" class="inline-block mb-2 " />
          <t-rich-select
            :options="tabs"
            valueAttribute="href"
            textAttribute="text"
            :hideSearchBox="true"
            @change="changeRoute"
            v-model="routeValue"
          />
        </div>

        <div class="px-5">
          <router-view />
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import OtoPageTitle from '@/components/ui/OtoPageTitle'
// import AnchorTabs from "@/components/tabs/AnchorTabs";
import SettingsNav from '@/composites/settings/SettingsNav'

export default {
  name: 'Settings',
  components: {
    BaseLayout,
    ContentSection,
    OtoPageTitle,
    // AnchorTabs
    SettingsNav,
  },
  data() {
    return {
      tabs: [
        {
          text: 'General',
          href: this.getTabHref(`general`),
        },
        {
          text: 'Security',
          href: this.getTabHref(`security`),
        },
        {
          text: 'Email',
          href: this.getTabHref(`email`),
        },
        {
          text: 'Rent',
          href: this.getTabHref(`trip`),
        },
        {
          text: 'Long Rent',
          href: this.getTabHref(`rent`),
        },
        {
          text: 'IoT',
          href: this.getTabHref(`iot`),
        },
        {
          text: 'Vehicle',
          href: this.getTabHref(`vehicle`),
        },
        {
          text: 'Events',
          href: this.getTabHref(`event`),
        },
        {
          text: 'Payments',
          href: this.getTabHref(`payment`),
        },
        {
          text: 'Point',
          href: this.getTabHref(`point`),
        },
        {
          text: 'Operator',
          href: this.getTabHref(`operator`),
        },
        {
          text: 'Rider App',
          href: this.getTabHref(`rider-app`),
        },

        {
          text: 'Business Hour',
          href: this.getTabHref(`schedule`),
        },
        {
          text: 'Quiz',
          href: this.getTabHref(`quiz`),
        },
        {
          text: 'Profile',
          href: this.getTabHref(`profile`),
        },
      ],
      routeValue: '/settings/general',
    }
  },
  mounted() {
    this.routeValue = this.$router.currentRoute.path ?? '/settings/general'
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/settings/${path}`
    },
    changeRoute(event) {
      this.$router.push({ path: event })
    },
  },
  created: () => {
    // this.$router.push({ path: '/settings/rider' })
  },
}
</script>

<style lang="scss" scoped>
.pl-main {
  padding-left: 4rem;
}
::v-deep .override {
  padding-left: 20px;
  padding-right: 20px;
  // width: 200px;
  nav {
    display: block !important;
    a {
      display: block !important;
      text-align: left;
      margin: 0;
    }
  }
}
::v-deep .override-2 {
  h2 {
    font-size: 22px;
  }
}
</style>
